@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

a {
    cursor: pointer;
}

/* Define pulse animation */
@keyframes pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}

:focus {
    outline: none !important;
}
#blocking {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
    animation: pulse-red 1s infinite;
}
#blob {
    border-radius: 50%;
    /* height: 30px;
  width: 30px; */
    transform: scale(0.6);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
    animation: pulse-red 1s infinite;
}

@keyframes pulse-red {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
    }
}

@keyframes pulse-green {
    0% {
        transform: scale(0.7);
        box-shadow: 0 0 0 0 rgba(40, 167, 69, 1);
    }

    70% {
        transform: scale(0.75);
        box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
    }

    100% {
        transform: scale(0.7);
        box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
    }
}

/* Needed for map to be visible */
html,
body {
    height: 100%;
    margin: 0;
    padding: 0;
}

#app {
    height: 100%;
}

#map-canvas {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

#map-canvas div[style*="pointer-events: none"] {
    border: none !important;
}

@keyframes blink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.fade-enter {
    animation: fade-in 0.3s ease-in-out forwards;
}
  
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

